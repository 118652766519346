import { Stack, Typography } from '@okta/odyssey-react-mui';
import { useTranslation } from '@okta/tools.react-i18n';
import icon from '../../../img/ico_noAccess.png';
import icon2x from '../../../img/ico_noAccess@2x.png';
import { useUser } from '../../models/common/UserProvider';
import { htmlToReact } from '../../utils/htmlToReact';
import { getDisplayHostname } from '../../utils/URIUtils';
import { Icon, Text, Title } from './shared';

const supportLink = '<a href="mailto:developers@okta.com">developers@okta.com</a>';

export const LandingPageError = () => {
  const { t } = useTranslation();
  const { signedIn, user } = useUser();

  if (!signedIn) {
    return;
  }
  const isDisabled = user.status === 'DISABLED';
  const companyName = user.company.name;
  const companyLinkedOrgUrl = getDisplayHostname(user.company.linkedOrgUrl);

  return (
    <Stack
      spacing={3}
      sx={(theme) => ({
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: { maxWidth: '92.5%' },
        [theme.breakpoints.up('lg')]: { maxWidth: '87.5%' },
        [theme.breakpoints.up('xl')]: { maxWidth: '80%' },
        paddingTop: '8rem',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      })}
    >
      <Icon src={icon} srcSet={`${icon2x} 2x`} />
      <Title variant="h1">
        {isDisabled
          ? t('landing.error.title.different', { companyName })
          : t('landing.error.title.access', { companyName })}
      </Title>
      <Text>
        {isDisabled
          ? htmlToReact(t('landing.error.description.different', { companyName, companyLinkedOrgUrl }))
          : htmlToReact(t('landing.error.description.access', { companyName, companyLinkedOrgUrl }))}
      </Text>
      <Text>{htmlToReact(t('landing.error.request', { companyLinkedOrgUrl, email: user.email }))}</Text>
      <Typography sx={{ fontSize: '0.85rem' }}>{htmlToReact(t('landing.error.contact', { supportLink }))}</Typography>
    </Stack>
  );
};
